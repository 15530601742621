<template>
  <section id="image-carousel" class="splide mb-3" aria-label="Beautiful Images">
    <div class="splide__track">
      <splide :options="options" :slides="slides">
        <splide-slide v-for="(slide, indx) in slides" :key="indx" :index="indx">
          <img :src="`${slide}`" alt="" style="height: 300px; width: 1200px;"/>
        </splide-slide>
      </splide>
    </div>
  </section>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  name: "HomeSlider",
  components: {
    Splide,
    SplideSlide,
  },
  props:['slides'],
  data() {
    return {
      options: {
        type: "loop",
        width : '100%',
        height: '300px',
        speed: 1200,
        gap: "10em",
        autoWidth: true,
        arrows:true,
        pagination: true,
        start  : 2,
        autoplay: true,
        focus: "center",
        trimSpace: "move",
        breakpoints: {
          "1200": {
            autoWidth: true,
          },
          "768": {
            arrows: false,
          },
          
        },
      },
    };
  },
};
</script>

