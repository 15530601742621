<template>
  <div class="home">
    <div class="main-content">
      <div class="fullwidth-template">
        <div><HomeSlider :slides="imgSrcs.dash"/></div>
        <div><CarouselFeatured /></div>
        <div><HomeGridProducts /></div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSlider from "../components/HomeSlider.vue";
import HomeGridProducts from "../components/HomeGridProducts.vue";
import CarouselFeatured from "../components/CarouselFeatured.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    HomeSlider,
    HomeGridProducts,
    CarouselFeatured,
  },

  computed: {
      ...mapGetters({
          imgSrcs: "ecommerce/imgSrcs"
        }),
  },
};
</script>

<style></style>
