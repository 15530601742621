<template>
    <div class="container" v-if="featured.length>0">
        <h3 class="custommenu-title-blog">
            Productos destacados
        </h3>

        <carousel
        :items="4"
        :responsive="responsive"
        :loop="false"
        :nav="true"
        :navText="navText"
        :autoplay="false"
        :margin="30">
            <div v-for="product in featured" :key="product.id" class="product-item">
              <ProductCard :product="product"/>
            </div>
        </carousel>
    </div>
</template>

<script>
import ProductCard from "./Product-card";
import { mapGetters, mapActions } from "vuex";
import carousel from 'vue-owl-carousel'

export default {
  components: {
    ProductCard,
    carousel,
  },
  data() {
    return {
      responsive: {
          0:{items:1,nav:false},
          600:{items:2,nav:true},
          992:{items:3,nav:true},
          1200:{items:4,nav:true}
        },
      navText:[
          "<div class='nav-btn prev-slide'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40'><path d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'></path></svg></div>",
          "<div class='nav-btn next-slide'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40'><path d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'></path></svg></div>"
        ],
    };
  },
  created() {
    this.loadFeatured();
  },
  computed: {
    ...mapGetters({
      featured: "ecommerce/featured"
    })
  },
  methods: {
    ...mapActions({loadFeatured: 'ecommerce/loadFeatured'})
  },
};
</script>

<style>
  .carousel-wrap {
    margin: auto;
    position: relative;
  }
  .owl-carousel .owl-nav{
    overflow: hidden;
    height: 0px;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
      background: var(--accent-color);
  }
  .owl-carousel .nav-btn{
      position:absolute;
      z-index:1;
      top:50%;
      transform:translateY(-50%);
      width:2em;
      height:2em;
      border-radius:50%;
      align-items:center;
      justify-content:center;
      border:none;
      padding:0;
      opacity:.7;
      background:#ccc
  }

  .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled{
    pointer-events: none;
    opacity: 0.2;
  }

  .owl-carousel .prev-slide{
      left:-3em;
  }
  .owl-carousel .next-slide{
      right:-3em;
  }
  .owl-carousel .prev-slide:hover,
  .owl-carousel .next-slide:hover{
     cursor:pointer;
     opacity:.9;
  }
  .owl-carousel .prev-slide:focus,
  .owl-carousel .next-slide:focus{
     outline:none;
  }

  .owl-carousel .prev-slide svg,
  .owl-carousel .next-slide svg{
     width:1.2em;
     height:1.2em;
    }

  .owl-carousel .prev-slide svg{
      transform:scaleX(-1) translateY(30%);
    }
  .owl-carousel .next-slide svg{
      transform:translateY(30%);
    }

</style>
